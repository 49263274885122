function sidebar() {
    var burger = $('.burger'),
        nav = $('.header__bottom');
        // close = document.querySelector('.sidebar__close');

    burger.on('click', function () {
        $(this).toggleClass('active')
        nav.toggleClass('active')
    });
    // close.addEventListener('click', function () {
    //     burger.classList.remove('active');
    // })
}

function request() {
    var btn = $('.request__control-btn'),
        parent = $('.request');

    btn.on('click', function () {
        parent.toggleClass('active');
    })
}

$(document).ready(function () {
    sidebar();
    request();
});